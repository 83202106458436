<template>
  <div class="default-layout">
    <TheHeader />

    <div class="main">
      <router-view />
    </div>

    <TheFooter />
  </div>
</template>

<script>
import TheHeader from "@/components/elements/public/shared/TheHeader";
import TheFooter from "@/components/elements/public/shared/TheFooter";

export default {
  components: {
    TheHeader,
    TheFooter,
  },
};
</script>

<style lang="scss" scoped>
//
</style>
