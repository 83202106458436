<template>
  <div class="yellow d-flex flex-column align-center justify-center text-left">
    <v-card class="mt-n2" width="100%" flat>
      <v-container class="mt-16">
        <v-img
          alt="Logo"
          class="AULLogo"
          src="@/assets/img/logo/SoftiDocBlack.png"
          width="100"
        />
        <v-row>
          <v-col cols="12" md="3"> </v-col>

          <v-col class="" cols="12" md="3">
            <h3 class="contactDetails mb-4 mt-4">Contact Us</h3>
            <div class="d-flex">
              <v-icon class="mr-1 mt-n3"> mdi-email-outline </v-icon>
              <p class="Details">info@softidoc.co.za</p>
            </div>

            <div class="d-flex">
              <v-icon class="mr-1 mt-n2"> mdi-cellphone </v-icon>
              <p class="Details mt-1">+27 84 326 0928</p>
            </div>
          </v-col>

          <v-col class="" cols="12" md="3">
            <h3 class="contactDetails mb-4 mt-4">Location</h3>
            <div class="d-flex">
              <v-icon class="mr-1"> mdi-map-marker-outline </v-icon>
              <p class="Details">
                Suite 15, Block 10, Boardwalk Office Park, Eros Road, Olympus
                AH, 0081
              </p>
            </div>
          </v-col>

          <v-col class="paragraph mt-3" cols="12" md="12">
            <hr class="hr" />
            <p class="mt-5 copyRight">
              SoftiDoc © Copyright - All rights reserved -
              {{ moment().year() }}
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "DocumentManagementTheFooter",
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
//

.manualDataCard {
  //   border-top-right-radius: 100px 70px;
  border-top-right-radius: 100px 70px;
}
.hr {
  border-color: #d3e1ff;
}
p {
  // font-weight: 100;
  font-size: 16px;
  line-height: 32px;
}
.contactDetails {
  font-weight: 700;
  font-size: 24px;
  line-height: 26.11px;
}
.Details {
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
}
.copyRight {
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
}
</style>
